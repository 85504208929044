@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  background-color: rgb(236, 236, 236) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ProjectApp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .ProjectApp {
    display: none;
  }
}

.Canvas {
  width: 100%;
  height: 100%;
}

.ProjectHeadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  line-height: normal;
  background-color: white;
  flex-direction: row;
}
.ProjectHeadContainer.BottomBordered {
  border-bottom: 1px solid lightgray;
}
.ProjectHeadContainer .TextContainer {
  display: block;
  text-align: center;
}
.ProjectHeadContainer .TextContainer .Title {
  font-size: 6vh;
}
.ProjectHeadContainer .TextContainer .SubTitle {
  font-size: 2vh;
}

.ProjectContentContainer {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
}
.ProjectContentContainer .ProjectTooltipContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  width: 30%;
  font-weight: 800;
  font-size: 1.8vh;
  text-align: center;
  line-height: normal;
}
.ProjectContentContainer .ProjectSelectionContainer {
  position: absolute;
  margin-top: auto;
  width: 50%;
  height: 15vh;
  align-self: flex-end;
  display: flex;
}

.ProjectContentContainer .ProjectSelectionContainer.VerticalPadded {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ProjectContentContainer .ProjectSelectionContainer .ButtonCollection {
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.ProjectContentContainer
  .ProjectSelectionContainer
  .ButtonCollection
  .SelectionButton {
  margin: 3px;
  max-height: 100px;
  object-fit: contain;
  max-width: 13%;
  box-shadow: var(--button-box-shadow);
}

.ProjectContentContainer .ProjectSelectionContainer .ProjectSelectionPadding {
  display: flex;
  flex: 1 1;
  padding-left: 20%;
  padding-right: 20%;
}
.ProjectContentContainer .ProjectSelectionContainer .ProjectSelectionCol {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}
.ProjectContentContainer
  .ProjectSelectionContainer
  .ProjectSelectionCol
  .ProjectSelectionRow {
  display: flex;
  flex: 1 1 auto;
}

.ProjectContentContainer .ProjectContentCol {
  display: flex;
  flex: 1 1;
  background-color: white;
}
.ProjectContentContainer .ProjectContentCol .ImpactGraph {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.ProjectContentContainer .ProjectContentCol .ImpactGraph .ImpactGraphContent {
  height: 100%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
}

.ProjectContentContainer .ComparisonMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 50px;
  height: 100%;
  z-index: 101;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.Top {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.MiddleTop {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.MiddleBottom {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.Bottom {
  margin-top: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem .MenuIcon {
  max-width: 100%;
  cursor: pointer;
}

.ProjectSelectionInput {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}
.ProjectSelectionInput .ProjectSelectionIcon {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProjectSelectionInput .ProjectSelectionIcon .Icon {
  max-width: 100%;
}
.ProjectSelectionInput .ProjectSelectionSlider {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
}
.ProjectSelectionInput .ProjectSelectionSlider .Slider {
  width: 100%;
}
.ProjectSelectionInput .ProjectSelectionUnit {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  font-weight: 800;
}

.ProjectInfoContainer {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1;
  overflow: auto;
  border-top: 1px solid lightgray;
}
.ProjectInfoContainer .NavigationCol {
  width: 8%;
  padding: 12px;
  display: flex;
}
.ProjectInfoContainer .NavigationCol.Left {
  margin-right: auto;
  justify-content: center;
  padding-top: 25px;
}
.ProjectInfoContainer .NavigationCol.Right {
  margin-left: auto;
  justify-content: center;
  padding-top: 25px;
}
.ProjectInfoContainer .NavigationCol .NavigationButton {
  display: block;
  max-width: 100%;
  max-height: 50px;
  height: auto;
  width: auto;
  cursor: pointer;
}

.ProjectInfoContainer .NavigationInfoCol {
  width: 40%;
  padding: 12px;
  text-align: start;
  display: flex;
  flex-direction: row;
}
.ProjectInfoContainer .NavigationInfoCol .Title {
  font-size: 1.2vh;
  font-weight: 700;
  padding-bottom: 12px;
}
.ProjectInfoContainer .NavigationInfoCol .Description {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.ProjectInfoContainer .GraphInfoCol {
  width: 40%;
  padding: 12px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.ProjectInfoContainer .GraphInfoCol .Title {
  display: flex;
  font-size: 2vh;
  font-weight: 700;
  padding-bottom: 12px;
  padding-top: 12px;
  justify-content: center;
}
.ProjectInfoContainer .GraphInfoCol .Description {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.ProjectInfoContainer .GraphInfoCol .Icon {
  padding-top: 20px;
  max-width: 100%;
}

.ProjectInfoContainer .MaterialInfoContainer {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-right: 10%;
  margin-left: 10%;
}
.ProjectInfoContainer .MaterialInfoContainer .Divider {
  border-right: 1px solid lightgray;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ProjectInfoContainer .MaterialInfoContainer .MaterialDetailsContainer {
  width: 35%;
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailIconCol {
  width: 25%;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailIconCol
  .MaterialIcon {
  max-width: 100%;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailTextCol {
  width: 75%;
  flex-direction: column;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailTextCol
  .MaterialDetailTextRow {
  padding-bottom: 12px;
}

.ProjectInfoContainer .MaterialInfoContainer .MaterialMenuContainer {
  width: 15%;
  padding: 20px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuIcon {
  color: white;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuIcon.Active {
  color: black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuItem {
  font-size: 1.2vh;
  font-weight: 700;
  cursor: pointer;
  color: lightgray;
  padding-bottom: 5px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuItem.Active {
  color: black;
}

.ProjectInfoContainer .MaterialInfoContainer .MaterialDescriptionContainer {
  width: 50%;
  padding: 20px;
  display: flex;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  border: 1px dashed black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol
  .MaterialDescriptionRow {
  display: flex;
  flex-direction: row;
  border: 1px dashed black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol
  .MaterialDescriptionRow
  .MaterialDescriptionRowCol {
  display: flex;
  flex: 1 1;
  border: 1px dashed black;
}

.ProConImg {
  max-width: 100%;
}
.ProductionImg {
  max-width: 100%;
}
.RecyclingImg {
  max-width: 100%;
}
.InfoIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background: rgb(29, 177, 41);
  color: white;
  font-weight: 500;
}

/* Mobile */
@media only screen and (max-width: 950px) {
  .ProjectContentContainer .ProjectTooltipContainer {
    width: 90%;
  }
  .ProjectInfoContainer .NavigationInfoCol {
    width: 90%;
  }
}

/* Common Components */
.ColDirection {
  display: flex;
  flex-direction: column;
}
.RowDirection {
  display: flex;
  flex-direction: row;
}
.Row {
  flex: 1 1;
}
.Col {
  flex: 1 1;
}

.Bordered {
  border: 1px dashed black;
}
.RightBordered {
  border-right: 1px solid lightgray;
  height: 100%;
}

.HorizontalEndAligned {
  justify-content: flex-end;
}
.HorizontalStartAligned {
  justify-content: flex-start;
}
.HorizontalCenterAligned {
  justify-content: center;
}
.VerticalEndAligned {
  align-items: flex-end;
}
.VerticalStartAligned {
  align-items: flex-start;
}
.VerticalMidAligned {
  align-items: center;
}
.TextAlignLeft {
  text-align: left;
}

.Hoverable:hover {
  transform: translate(0, -5px);
}
.HoverButton {
  box-shadow: 0px 5px 14px -2px rgba(0, 0, 0, 0.2);
}
.HoverButton:hover {
  transform: translate(0, -5px);
}
.HoverSelection:hover {
  transform: translate(0, -5px);
}
.BoldHead1 {
  font-size: 1.2vh;
  font-weight: 700;
}
.Text1 {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.Text2 {
  font-size: 1vh;
  font-weight: 500;
  line-height: normal;
}

.ResultTableTopRow {
  display: flex;
  font-size: 1.5vh;
  font-weight: 500;
}
.ResultTableRow {
  display: flex;
  font-size: 1vh;
  font-weight: 500;
  line-height: normal;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ResultTableTitleCell {
  display: flex;
  flex: 1 1;
}
.ResultTableResultCell {
  display: flex;
  flex: 1.5 1;
}

.impact-graph {
  display: flex;
  flex: 1 1;
  text-align: center;
  padding: 5% 20%;
}

.impact-graph .divider {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin: 1rem 0;
}

.impact-saved .title {
  font-size: 20px;
}

.impact-saved .calculation {
  font-size: 30px;
}

.impact-saved .result {
  margin-top: 60px;
  font-size: 120px;
  font-weight: 700;
  color: rgb(119, 175, 24);
}

.impact-saved .result-description {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 1rem;
}

.impact-saved .result-info-container {
  display: flex;
  flex-direction: row;
}

.impact-saved .result-info-icon-container {
  display: block;
  flex: 1 1;
}

.impact-saved .result-info-icon {
  max-width: 150px;
  width: auto;
  height: auto;
}

.impact-saved .result-info-description {
  text-align: left;
  font-size: 1.2vh;
  line-height: normal;
  font-weight: 500;
}

.impact-info .impact-info-title-row {
  display: flex;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.impact-info .impact-info-row {
  display: flex;
  flex-direction: row;
}

.impact-info .impact-info-col-img {
  display: flex;
  flex: 1 1;
}

.impact-info .impact-info-img {
  max-height: 100px;
}

.impact-info .impact-info-col-percentage {
  display: flex;
  flex: 1 1;
  align-items: center;
  font-weight: 700;
  font-size: 36px;
}

.impact-info .impact-info-col-title {
  display: flex;
  flex: 3 1;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}

.GuideModal {
    display: flex !important;
    flex-direction: row !important;
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0 !important;
}

.GuideModal .GuideModalContent {
    display: flex;
    flex-direction: row;
}
.GuideModal .GuideModalContent .GuideModalNavCol {
    display: flex;
    min-width: 100px;
}
.GuideModal .GuideModalContent .GuideModalNavCol.Left { justify-content: flex-start; }
.GuideModal .GuideModalContent .GuideModalNavCol.Right { justify-content: flex-end; }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButton { background-color: rgba(0, 0, 0, 0); }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButton.Disabled { display: none !important; }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButtonIcon { color: white; }

.GuideModal .GuideModalContent .GuideModalImgCol {
    display: flex;
    flex: 1 1;
    justify-content: center;
}
.GuideModal .GuideModalContent .GuideModalImgCol .GuideImage {
    max-width: 100%;
}

.guide-modal-close {
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
    cursor: pointer;
    width: 30px;
}
.comparison-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  line-height: normal;
  background-color: white;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
}

.comparison-header .comparison-header-col {
  display: flex;
  flex: 1 1;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: row;
  height: 100%;
}

.comparison-header .comparison-header-col.left {
  padding-left: 5%;
  padding-right: 1%;
}

.comparison-header .comparison-header-col.right {
  padding-left: 1%;
  padding-right: 5%;
}

.comparison-header .building-info-col {
  width: 25%;
  height: 100%;
}

.comparison-header .building-info-name-row {
  display: flex;
  height: 40%;
  font-weight: 500;
  font-size: 28px;
}

.comparison-header .building-info-name-row.left {
  justify-content: flex-start;
}

.comparison-header .building-info-name-row.right {
  justify-content: flex-end;
}

.comparison-header .building-number-col {
  display: flex;
  width: 35%;
  height: 100%;
  line-height: 8vh;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 10vh;
}

.comparison-header .building-units-col {
  display: flex;
  width: 25%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}

.comparison-header .building-units-row {
  display: flex;
  align-items: center;
}

.comparison-header .carbon-capture-text {
  font-size: 10px;
  font-weight: 700;
  color: #77af18;
}

.comparison-header .carbon-capture-label {
  font-size: 10px;
  font-weight: 700;
  padding: 4px;
  background-color: rgb(119, 175, 24);
  color: white;
}

.comparison-header .indicator-text {
  font-size: 10px;
  font-weight: 700;
}

.comparison-header .unit-text {
  font-size: 20px;
  font-weight: 700;
}

.comparison-header .building-results-col {
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.comparison-header .building-results-row {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  padding-bottom: 2px;
}

.comparison-header .building-results-cell {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  font-size: 1rem;
}

.comparison-header .building-results-icon {
  max-height: 25px;
}

.icons-row {
  display: flex;
  height: 60%;
  flex-direction: row;
}

.icons-row .icons-cell {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
}

.icons-row .icons-figure {
  margin: 0;
  padding: 0;
}

.icons-row .icons-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
  max-width: 80%;
  min-height: 25px;
}

.icons-row .icons-text {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 700;
}

.mobile-restriction {
  display: none;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .mobile-restriction {
    display: flex;
  }
}

.tablet-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightyellow;
  height: 40px;
  font-weight: 500;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

@media only screen and (max-width: 640px) {
  .tablet-warning {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .tablet-warning {
    display: none;
  }
}

/* FORMS */
.PageForm { display: flex; justify-content: center; text-align: left; margin-top: 20px; padding-top: 20px; }
.PageForm .FormContainer { display: flex; width: 30%; flex-direction: column; }
.PageForm .FormContainer .FormRow { display: flex; }
.PageForm .FormContainer .FormRow .FormCol { display: flex; flex: 1 1; align-items: center; }
.PageForm .FormContainer .FormRow .FormCol.Right { justify-content: flex-end; text-align: right;  }

@media only screen and (max-width: 800px) {
    .PageForm .FormContainer { width: 80%; }    
}

/* CONTENT */
.PageContent { margin-top: 60px !important; }

.ContentHeader { display: flex; justify-content: center; align-items: center; width: 100%; height: 150px; line-height: normal; padding: 12px }
.ContentHeader.White { background-color: white; }
.ContentHeader .TextContainer { display: block; text-align: center; }
.ContentHeader .TextContainer .Title { font-size: 6vh; }
.ContentHeader .TextContainer .SubTitle { font-size: 2vh; }

.ContentContainer { width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; font-weight: 500; padding-top: 20px; }
.ContentContainer .ContentRow { display: flex; width: 60%; padding-top: 12px; padding-bottom: 12px; }
.ContentContainer .ContentRow.Centered { justify-content: center; }
.ContentContainer .ContentRow.Underlined { text-decoration: underline; }
.ContentContainer .ContentRow.TextHead { font-size: 1.6vh; font-weight: 700; }
.ContentContainer .ContentRow.TextContent { font-size: 1.2vh; }
.ContentContainer .ContentRow .ContentCol { display: flex; flex: 1 1; }
.ContentContainer .ContentRow .ContentCol.Centered { justify-content: center; }
.ContentContainer .ContentRow .ContentCol .Logo { display: block; max-height: 100px; width: auto; height: auto; }
.ContentContainer .ContentRow .TextBlock { display: block; list-style-type: none; }
.ContentContainer .ContentRow .TextBlock .TextList { list-style-type: none; padding: 0; margin: 0; }
.ContentContainer .ContentRow .TextBlock .TextLink { color: black; font-weight: 700 !important; }

.ContentContainer .ContentRow .RowHeader  { font-size: 1.6vh; font-weight: 700; padding-bottom: 12px; }
.ContentContainer .ContentRow .RowText  { font-size: 1.2vh; padding-bottom: 12px;  }

.ProfileList { display: flex; flex: 1 1; flex-direction: column; }
.ListItem { display: flex; flex-direction: row; border-bottom: 1px solid black; padding-left: 12px; padding-top: 12px; padding-bottom: 12px; }
.ListItem .Content { display: flex; flex: 1 1; flex-direction: column; }
.ListItem .Content .Title { display: flex; flex: 1 1; font-size: 20px; font-weight: 800; margin-bottom: 10px; }
.ListItem .Content .Subtitle { display: flex; flex: 1 1; font-size: 14px; font-weight: 300; color: darkgray; }
.ListItem .Actions { display: flex; align-items: center; }
.ListItem .Actions .Button { border-radius: 0px; background-color: white; font-size: 18px; border: none; font-family: 'Montserrat', sans-serif !important; font-weight: 700; min-width: 120px; min-height: 40px; box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.1); cursor: pointer; }
.ListItem .Actions .Button:hover { background-color: rgb(245, 245, 245); }
.ListItem .Actions .Button:focus { outline: none; }
.ListItem .Actions .Button.Red { background-color: rgb(224, 85, 83); color: white; }

@media only screen and (max-width: 800px) {
    .ContentContainer .ContentRow { width: 80%; }    
}


.PaddedTop { padding-top: 12px; }
.PaddedBottom { padding-bottom: 12px; }
.intro-graphic {
  height: auto;
  width: auto;
  max-width: 65%;
  margin: auto;
}

.text-section-header {
  margin-bottom: var(--header-margin-bottom);
}

.text-section-header > span {
  font-size: var(--header-font-size);
}

.text-section-content > span {
  font-size: var(--normal-font-size);
}

.text-section-footer > li {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-sources {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-header {
  margin-bottom: var(--header-margin-bottom);
}

.text-section-header > span {
  font-size: var(--header-font-size);
}

.text-section-content > span {
  font-size: var(--normal-font-size);
}

.text-section-footer > li {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-sources {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-header {
  margin-bottom: var(--header-margin-bottom);
}

.text-section-header > span {
  font-size: var(--header-font-size);
}

.text-section-content > span {
  font-size: var(--normal-font-size);
}

.text-section-footer > li {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-sources {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.faq-category {
    margin-top: 12px;
    margin-bottom: 12px;
}

.faq-category-header {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 12px;
}

.faq-accordion {
    display: flex;
    flex-direction: column;
}

.faq-question {
    font-weight: 700;
    font-family: "Montserrat" !important;
    display: flex;
    padding-bottom: 0px !important;
}

.faq-question-icon {
    width: 20px;
}

.faq-answer {
    font-weight: 500;
    margin-bottom: 12px !important;
    margin-left: 20px !important;
}

.contact-text {
    font-weight: 500;
}
.page-not-found-text {
  font-size: 16px;
  font-weight: 500;
}
.PageHeader {
  font-family: "Montserrat", sans-serif !important;
  height: 60px;
  border-radius: 0 !important;
}
.PageHeader .HeaderLogo {
  font-size: 18px;
  font-weight: 200;
}
.PageHeader .HeaderLogo .Beta {
  padding-left: 4px;
  padding-top: 6px;
  font-size: 8px;
  font-weight: 700;
  height: 100%;
  color: yellow;
}
.PageHeader .HeaderItem {
  font-weight: 500 !important;
}
.PageHeader .HeaderItem.Secondary {
  color: darkgrey !important;
}
.PageHeader .ProjectHeaderItem {
  font-weight: 700 !important;
  font-size: medium;
  margin-right: auto;
  color: rgb(119, 175, 24) !important;
}
.PageHeader .HeaderItemMobile {
  display: none !important;
  margin-left: auto;
}
.PageHeader .HeaderItemMobile .HeaderItemIcon {
  margin: 0 !important;
}
.PageHeader .ProjectsDropwdown {
  margin-left: auto;
}

.PageMessage {
  width: 300px;
  min-height: 50px;
  position: absolute;
  border: 1px solid black;
  right: 10px;
  top: 70px; /* PageHeader (60px) + 10px */
  border-radius: 6px;
  background-color: white;
  padding: 12px;
  font-weight: 500;
  z-index: 1400;
}
.PageMessage .CloseButton {
  position: absolute !important;
  top: 0px;
  right: 0px;
  cursor: pointer;
  color: darkgray;
}

@media only screen and (max-width: 1000px) {
  .PageHeader .HeaderItem {
    display: none !important;
  }
  .PageHeader .ProjectHeaderItem {
    display: none !important;
  }
  .PageHeader .HeaderItemMobile {
    display: flex !important;
  }
  .PageHeader .ProjectsDropwdown {
    display: none !important;
  }
}

/* ROOT SETTINGS */
:root {
  --mobile-width-breakpoint: 640px;
  --tablet-width-breakpoint: 1024px;
  --laptop-width-breakpoint: 1280px;

  --header-font-size: 18px;
  --header-margin-bottom: 1.6em;
  --normal-font-size: 12px;
  --secondary-font-size: 12px;
  --secondary-font-weight: 300;

  --button-box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

/* Webkit slider */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animation: 0.2s;
  background: #000000;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 12px;
  border-radius: 0;
  background: black;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
/* Firefox slider */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  /*animate: 0.2s;*/
  background: #000000;
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 12px;
  border-radius: 0;
  background: black;
  cursor: pointer;
}

.LoaderSegment {
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.ListContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
.ListContent {
  width: 50%;
}
.ProjectList {
  background-color: transparent !important;
  border: none !important;
  flex-direction: column;
}
.ProjectListRow {
  display: flex;
  flex: 1 1;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid black;
  flex-direction: row;
}
.ProjectListName {
  display: flex;
  flex: 1 1;
}
.ProjectListActions {
  display: flex;
  justify-content: flex-end;
}

.ProjectList .Actions {
  font-size: large;
  font-weight: 700;
  color: black;
  cursor: pointer;
}
.ProjectList .Title {
  font-size: large;
  font-weight: 300;
  color: black;
  cursor: pointer;
}
.ProjectList .NameInput {
  font-family: "Montserrat", sans-serif !important;
  border: none;
  font-size: large;
  font-weight: 300;
  color: black;
  padding: 0px;
  width: 100%;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.ProjectList .NameInput.Active {
  background-color: white;
  cursor: text;
}
.ProjectList .NameInputSave {
  display: none;
  cursor: pointer;
}
.ProjectList .NameInputSave.Active {
  display: flex;
}
.ProjectList .NameInputCancel {
  display: none;
  cursor: pointer;
}
.ProjectList .NameInputCancel.Active {
  display: flex;
}

