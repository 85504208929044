.text-section-header {
  margin-bottom: var(--header-margin-bottom);
}

.text-section-header > span {
  font-size: var(--header-font-size);
}

.text-section-content > span {
  font-size: var(--normal-font-size);
}

.text-section-footer > li {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}

.text-section-sources {
  font-size: var(--secondary-font-size);
  font-weight: var(--secondary-font-weight);
  line-height: normal;
}
