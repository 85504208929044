.PageHeader {
  font-family: "Montserrat", sans-serif !important;
  height: 60px;
  border-radius: 0 !important;
}
.PageHeader .HeaderLogo {
  font-size: 18px;
  font-weight: 200;
}
.PageHeader .HeaderLogo .Beta {
  padding-left: 4px;
  padding-top: 6px;
  font-size: 8px;
  font-weight: 700;
  height: 100%;
  color: yellow;
}
.PageHeader .HeaderItem {
  font-weight: 500 !important;
}
.PageHeader .HeaderItem.Secondary {
  color: darkgrey !important;
}
.PageHeader .ProjectHeaderItem {
  font-weight: 700 !important;
  font-size: medium;
  margin-right: auto;
  color: rgb(119, 175, 24) !important;
}
.PageHeader .HeaderItemMobile {
  display: none !important;
  margin-left: auto;
}
.PageHeader .HeaderItemMobile .HeaderItemIcon {
  margin: 0 !important;
}
.PageHeader .ProjectsDropwdown {
  margin-left: auto;
}

.PageMessage {
  width: 300px;
  min-height: 50px;
  position: absolute;
  border: 1px solid black;
  right: 10px;
  top: 70px; /* PageHeader (60px) + 10px */
  border-radius: 6px;
  background-color: white;
  padding: 12px;
  font-weight: 500;
  z-index: 1400;
}
.PageMessage .CloseButton {
  position: absolute !important;
  top: 0px;
  right: 0px;
  cursor: pointer;
  color: darkgray;
}

@media only screen and (max-width: 1000px) {
  .PageHeader .HeaderItem {
    display: none !important;
  }
  .PageHeader .ProjectHeaderItem {
    display: none !important;
  }
  .PageHeader .HeaderItemMobile {
    display: flex !important;
  }
  .PageHeader .ProjectsDropwdown {
    display: none !important;
  }
}
