.tablet-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightyellow;
  height: 40px;
  font-weight: 500;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

@media only screen and (max-width: 640px) {
  .tablet-warning {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .tablet-warning {
    display: none;
  }
}
