.ProjectApp {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .ProjectApp {
    display: none;
  }
}

.Canvas {
  width: 100%;
  height: 100%;
}

.ProjectHeadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  line-height: normal;
  background-color: white;
  flex-direction: row;
}
.ProjectHeadContainer.BottomBordered {
  border-bottom: 1px solid lightgray;
}
.ProjectHeadContainer .TextContainer {
  display: block;
  text-align: center;
}
.ProjectHeadContainer .TextContainer .Title {
  font-size: 6vh;
}
.ProjectHeadContainer .TextContainer .SubTitle {
  font-size: 2vh;
}

.ProjectContentContainer {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
}
.ProjectContentContainer .ProjectTooltipContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  width: 30%;
  font-weight: 800;
  font-size: 1.8vh;
  text-align: center;
  line-height: normal;
}
.ProjectContentContainer .ProjectSelectionContainer {
  position: absolute;
  margin-top: auto;
  width: 50%;
  height: 15vh;
  align-self: flex-end;
  display: flex;
}

.ProjectContentContainer .ProjectSelectionContainer.VerticalPadded {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ProjectContentContainer .ProjectSelectionContainer .ButtonCollection {
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.ProjectContentContainer
  .ProjectSelectionContainer
  .ButtonCollection
  .SelectionButton {
  margin: 3px;
  max-height: 100px;
  object-fit: contain;
  max-width: 13%;
  box-shadow: var(--button-box-shadow);
}

.ProjectContentContainer .ProjectSelectionContainer .ProjectSelectionPadding {
  display: flex;
  flex: 1;
  padding-left: 20%;
  padding-right: 20%;
}
.ProjectContentContainer .ProjectSelectionContainer .ProjectSelectionCol {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.ProjectContentContainer
  .ProjectSelectionContainer
  .ProjectSelectionCol
  .ProjectSelectionRow {
  display: flex;
  flex: 1 1 auto;
}

.ProjectContentContainer .ProjectContentCol {
  display: flex;
  flex: 1;
  background-color: white;
}
.ProjectContentContainer .ProjectContentCol .ImpactGraph {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.ProjectContentContainer .ProjectContentCol .ImpactGraph .ImpactGraphContent {
  height: 100%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
}

.ProjectContentContainer .ComparisonMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 50px;
  height: 100%;
  z-index: 101;
  padding-top: 12px;
  padding-bottom: 12px;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.Top {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.MiddleTop {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.MiddleBottom {
  margin-bottom: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem.Bottom {
  margin-top: auto;
}
.ProjectContentContainer .ComparisonMenu .MenuItem .MenuIcon {
  max-width: 100%;
  cursor: pointer;
}

.ProjectSelectionInput {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.ProjectSelectionInput .ProjectSelectionIcon {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProjectSelectionInput .ProjectSelectionIcon .Icon {
  max-width: 100%;
}
.ProjectSelectionInput .ProjectSelectionSlider {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
}
.ProjectSelectionInput .ProjectSelectionSlider .Slider {
  width: 100%;
}
.ProjectSelectionInput .ProjectSelectionUnit {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: large;
  font-weight: 800;
}

.ProjectInfoContainer {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: auto;
  border-top: 1px solid lightgray;
}
.ProjectInfoContainer .NavigationCol {
  width: 8%;
  padding: 12px;
  display: flex;
}
.ProjectInfoContainer .NavigationCol.Left {
  margin-right: auto;
  justify-content: center;
  padding-top: 25px;
}
.ProjectInfoContainer .NavigationCol.Right {
  margin-left: auto;
  justify-content: center;
  padding-top: 25px;
}
.ProjectInfoContainer .NavigationCol .NavigationButton {
  display: block;
  max-width: 100%;
  max-height: 50px;
  height: auto;
  width: auto;
  cursor: pointer;
}

.ProjectInfoContainer .NavigationInfoCol {
  width: 40%;
  padding: 12px;
  text-align: start;
  display: flex;
  flex-direction: row;
}
.ProjectInfoContainer .NavigationInfoCol .Title {
  font-size: 1.2vh;
  font-weight: 700;
  padding-bottom: 12px;
}
.ProjectInfoContainer .NavigationInfoCol .Description {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.ProjectInfoContainer .GraphInfoCol {
  width: 40%;
  padding: 12px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.ProjectInfoContainer .GraphInfoCol .Title {
  display: flex;
  font-size: 2vh;
  font-weight: 700;
  padding-bottom: 12px;
  padding-top: 12px;
  justify-content: center;
}
.ProjectInfoContainer .GraphInfoCol .Description {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.ProjectInfoContainer .GraphInfoCol .Icon {
  padding-top: 20px;
  max-width: 100%;
}

.ProjectInfoContainer .MaterialInfoContainer {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-right: 10%;
  margin-left: 10%;
}
.ProjectInfoContainer .MaterialInfoContainer .Divider {
  border-right: 1px solid lightgray;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ProjectInfoContainer .MaterialInfoContainer .MaterialDetailsContainer {
  width: 35%;
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailIconCol {
  width: 25%;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailIconCol
  .MaterialIcon {
  max-width: 100%;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailTextCol {
  width: 75%;
  flex-direction: column;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDetailsContainer
  .MaterialDetailTextCol
  .MaterialDetailTextRow {
  padding-bottom: 12px;
}

.ProjectInfoContainer .MaterialInfoContainer .MaterialMenuContainer {
  width: 15%;
  padding: 20px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuIcon {
  color: white;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuIcon.Active {
  color: black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuItem {
  font-size: 1.2vh;
  font-weight: 700;
  cursor: pointer;
  color: lightgray;
  padding-bottom: 5px;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialMenuContainer
  .MaterialMenuItem.Active {
  color: black;
}

.ProjectInfoContainer .MaterialInfoContainer .MaterialDescriptionContainer {
  width: 50%;
  padding: 20px;
  display: flex;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px dashed black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol
  .MaterialDescriptionRow {
  display: flex;
  flex-direction: row;
  border: 1px dashed black;
}
.ProjectInfoContainer
  .MaterialInfoContainer
  .MaterialDescriptionContainer
  .MaterialDescriptionCol
  .MaterialDescriptionRow
  .MaterialDescriptionRowCol {
  display: flex;
  flex: 1;
  border: 1px dashed black;
}

.ProConImg {
  max-width: 100%;
}
.ProductionImg {
  max-width: 100%;
}
.RecyclingImg {
  max-width: 100%;
}
.InfoIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background: rgb(29, 177, 41);
  color: white;
  font-weight: 500;
}

/* Mobile */
@media only screen and (max-width: 950px) {
  .ProjectContentContainer .ProjectTooltipContainer {
    width: 90%;
  }
  .ProjectInfoContainer .NavigationInfoCol {
    width: 90%;
  }
}

/* Common Components */
.ColDirection {
  display: flex;
  flex-direction: column;
}
.RowDirection {
  display: flex;
  flex-direction: row;
}
.Row {
  flex: 1;
}
.Col {
  flex: 1;
}

.Bordered {
  border: 1px dashed black;
}
.RightBordered {
  border-right: 1px solid lightgray;
  height: 100%;
}

.HorizontalEndAligned {
  justify-content: flex-end;
}
.HorizontalStartAligned {
  justify-content: flex-start;
}
.HorizontalCenterAligned {
  justify-content: center;
}
.VerticalEndAligned {
  align-items: flex-end;
}
.VerticalStartAligned {
  align-items: flex-start;
}
.VerticalMidAligned {
  align-items: center;
}
.TextAlignLeft {
  text-align: left;
}

.Hoverable:hover {
  transform: translate(0, -5px);
}
.HoverButton {
  box-shadow: 0px 5px 14px -2px rgba(0, 0, 0, 0.2);
}
.HoverButton:hover {
  transform: translate(0, -5px);
}
.HoverSelection:hover {
  transform: translate(0, -5px);
}
.BoldHead1 {
  font-size: 1.2vh;
  font-weight: 700;
}
.Text1 {
  font-size: 1.2vh;
  font-weight: 500;
  line-height: normal;
}
.Text2 {
  font-size: 1vh;
  font-weight: 500;
  line-height: normal;
}

.ResultTableTopRow {
  display: flex;
  font-size: 1.5vh;
  font-weight: 500;
}
.ResultTableRow {
  display: flex;
  font-size: 1vh;
  font-weight: 500;
  line-height: normal;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ResultTableTitleCell {
  display: flex;
  flex: 1;
}
.ResultTableResultCell {
  display: flex;
  flex: 1.5;
}
