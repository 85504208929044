.faq-category {
    margin-top: 12px;
    margin-bottom: 12px;
}

.faq-category-header {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 12px;
}

.faq-accordion {
    display: flex;
    flex-direction: column;
}

.faq-question {
    font-weight: 700;
    font-family: "Montserrat" !important;
    display: flex;
    padding-bottom: 0px !important;
}

.faq-question-icon {
    width: 20px;
}

.faq-answer {
    font-weight: 500;
    margin-bottom: 12px !important;
    margin-left: 20px !important;
}

.contact-text {
    font-weight: 500;
}