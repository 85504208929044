.icons-row {
  display: flex;
  height: 60%;
  flex-direction: row;
}

.icons-row .icons-cell {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.icons-row .icons-figure {
  margin: 0;
  padding: 0;
}

.icons-row .icons-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
  max-width: 80%;
  min-height: 25px;
}

.icons-row .icons-text {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 700;
}
