.GuideModal {
    display: flex !important;
    flex-direction: row !important;
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0 !important;
}

.GuideModal .GuideModalContent {
    display: flex;
    flex-direction: row;
}
.GuideModal .GuideModalContent .GuideModalNavCol {
    display: flex;
    min-width: 100px;
}
.GuideModal .GuideModalContent .GuideModalNavCol.Left { justify-content: flex-start; }
.GuideModal .GuideModalContent .GuideModalNavCol.Right { justify-content: flex-end; }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButton { background-color: rgba(0, 0, 0, 0); }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButton.Disabled { display: none !important; }
.GuideModal .GuideModalContent .GuideModalNavCol .NavButtonIcon { color: white; }

.GuideModal .GuideModalContent .GuideModalImgCol {
    display: flex;
    flex: 1 1;
    justify-content: center;
}
.GuideModal .GuideModalContent .GuideModalImgCol .GuideImage {
    max-width: 100%;
}

.guide-modal-close {
    position: absolute !important;
    right: 20px !important;
    top: 20px !important;
    cursor: pointer;
    width: 30px;
}