/* ROOT SETTINGS */
:root {
  --mobile-width-breakpoint: 640px;
  --tablet-width-breakpoint: 1024px;
  --laptop-width-breakpoint: 1280px;

  --header-font-size: 18px;
  --header-margin-bottom: 1.6em;
  --normal-font-size: 12px;
  --secondary-font-size: 12px;
  --secondary-font-weight: 300;

  --button-box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

/* Webkit slider */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animation: 0.2s;
  background: #000000;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 12px;
  border-radius: 0;
  background: black;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
/* Firefox slider */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  /*animate: 0.2s;*/
  background: #000000;
}

input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 12px;
  border-radius: 0;
  background: black;
  cursor: pointer;
}

.LoaderSegment {
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.ListContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
.ListContent {
  width: 50%;
}
.ProjectList {
  background-color: transparent !important;
  border: none !important;
  flex-direction: column;
}
.ProjectListRow {
  display: flex;
  flex: 1;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid black;
  flex-direction: row;
}
.ProjectListName {
  display: flex;
  flex: 1;
}
.ProjectListActions {
  display: flex;
  justify-content: flex-end;
}

.ProjectList .Actions {
  font-size: large;
  font-weight: 700;
  color: black;
  cursor: pointer;
}
.ProjectList .Title {
  font-size: large;
  font-weight: 300;
  color: black;
  cursor: pointer;
}
.ProjectList .NameInput {
  font-family: "Montserrat", sans-serif !important;
  border: none;
  font-size: large;
  font-weight: 300;
  color: black;
  padding: 0px;
  width: 100%;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.ProjectList .NameInput.Active {
  background-color: white;
  cursor: text;
}
.ProjectList .NameInputSave {
  display: none;
  cursor: pointer;
}
.ProjectList .NameInputSave.Active {
  display: flex;
}
.ProjectList .NameInputCancel {
  display: none;
  cursor: pointer;
}
.ProjectList .NameInputCancel.Active {
  display: flex;
}
