.comparison-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  line-height: normal;
  background-color: white;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
}

.comparison-header .comparison-header-col {
  display: flex;
  flex: 1 1;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: row;
  height: 100%;
}

.comparison-header .comparison-header-col.left {
  padding-left: 5%;
  padding-right: 1%;
}

.comparison-header .comparison-header-col.right {
  padding-left: 1%;
  padding-right: 5%;
}

.comparison-header .building-info-col {
  width: 25%;
  height: 100%;
}

.comparison-header .building-info-name-row {
  display: flex;
  height: 40%;
  font-weight: 500;
  font-size: 28px;
}

.comparison-header .building-info-name-row.left {
  justify-content: flex-start;
}

.comparison-header .building-info-name-row.right {
  justify-content: flex-end;
}

.comparison-header .building-number-col {
  display: flex;
  width: 35%;
  height: 100%;
  line-height: 8vh;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 10vh;
}

.comparison-header .building-units-col {
  display: flex;
  width: 25%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}

.comparison-header .building-units-row {
  display: flex;
  align-items: center;
}

.comparison-header .carbon-capture-text {
  font-size: 10px;
  font-weight: 700;
  color: #77af18;
}

.comparison-header .carbon-capture-label {
  font-size: 10px;
  font-weight: 700;
  padding: 4px;
  background-color: rgb(119, 175, 24);
  color: white;
}

.comparison-header .indicator-text {
  font-size: 10px;
  font-weight: 700;
}

.comparison-header .unit-text {
  font-size: 20px;
  font-weight: 700;
}

.comparison-header .building-results-col {
  display: flex;
  width: 15%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.comparison-header .building-results-row {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  padding-bottom: 2px;
}

.comparison-header .building-results-cell {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  font-size: 1rem;
}

.comparison-header .building-results-icon {
  max-height: 25px;
}
