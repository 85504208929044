/* FORMS */
.PageForm { display: flex; justify-content: center; text-align: left; margin-top: 20px; padding-top: 20px; }
.PageForm .FormContainer { display: flex; width: 30%; flex-direction: column; }
.PageForm .FormContainer .FormRow { display: flex; }
.PageForm .FormContainer .FormRow .FormCol { display: flex; flex: 1; align-items: center; }
.PageForm .FormContainer .FormRow .FormCol.Right { justify-content: flex-end; text-align: right;  }

@media only screen and (max-width: 800px) {
    .PageForm .FormContainer { width: 80%; }    
}

/* CONTENT */
.PageContent { margin-top: 60px !important; }

.ContentHeader { display: flex; justify-content: center; align-items: center; width: 100%; height: 150px; line-height: normal; padding: 12px }
.ContentHeader.White { background-color: white; }
.ContentHeader .TextContainer { display: block; text-align: center; }
.ContentHeader .TextContainer .Title { font-size: 6vh; }
.ContentHeader .TextContainer .SubTitle { font-size: 2vh; }

.ContentContainer { width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; font-weight: 500; padding-top: 20px; }
.ContentContainer .ContentRow { display: flex; width: 60%; padding-top: 12px; padding-bottom: 12px; }
.ContentContainer .ContentRow.Centered { justify-content: center; }
.ContentContainer .ContentRow.Underlined { text-decoration: underline; }
.ContentContainer .ContentRow.TextHead { font-size: 1.6vh; font-weight: 700; }
.ContentContainer .ContentRow.TextContent { font-size: 1.2vh; }
.ContentContainer .ContentRow .ContentCol { display: flex; flex: 1; }
.ContentContainer .ContentRow .ContentCol.Centered { justify-content: center; }
.ContentContainer .ContentRow .ContentCol .Logo { display: block; max-height: 100px; width: auto; height: auto; }
.ContentContainer .ContentRow .TextBlock { display: block; list-style-type: none; }
.ContentContainer .ContentRow .TextBlock .TextList { list-style-type: none; padding: 0; margin: 0; }
.ContentContainer .ContentRow .TextBlock .TextLink { color: black; font-weight: 700 !important; }

.ContentContainer .ContentRow .RowHeader  { font-size: 1.6vh; font-weight: 700; padding-bottom: 12px; }
.ContentContainer .ContentRow .RowText  { font-size: 1.2vh; padding-bottom: 12px;  }

.ProfileList { display: flex; flex: 1; flex-direction: column; }
.ListItem { display: flex; flex-direction: row; border-bottom: 1px solid black; padding-left: 12px; padding-top: 12px; padding-bottom: 12px; }
.ListItem .Content { display: flex; flex: 1; flex-direction: column; }
.ListItem .Content .Title { display: flex; flex: 1; font-size: 20px; font-weight: 800; margin-bottom: 10px; }
.ListItem .Content .Subtitle { display: flex; flex: 1; font-size: 14px; font-weight: 300; color: darkgray; }
.ListItem .Actions { display: flex; align-items: center; }
.ListItem .Actions .Button { border-radius: 0px; background-color: white; font-size: 18px; border: none; font-family: 'Montserrat', sans-serif !important; font-weight: 700; min-width: 120px; min-height: 40px; box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.1); cursor: pointer; }
.ListItem .Actions .Button:hover { background-color: rgb(245, 245, 245); }
.ListItem .Actions .Button:focus { outline: none; }
.ListItem .Actions .Button.Red { background-color: rgb(224, 85, 83); color: white; }

@media only screen and (max-width: 800px) {
    .ContentContainer .ContentRow { width: 80%; }    
}


.PaddedTop { padding-top: 12px; }
.PaddedBottom { padding-bottom: 12px; }