.mobile-restriction {
  display: none;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 12px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .mobile-restriction {
    display: flex;
  }
}
