.impact-graph {
  display: flex;
  flex: 1;
  text-align: center;
  padding: 5% 20%;
}

.impact-graph .divider {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin: 1rem 0;
}

.impact-saved .title {
  font-size: 20px;
}

.impact-saved .calculation {
  font-size: 30px;
}

.impact-saved .result {
  margin-top: 60px;
  font-size: 120px;
  font-weight: 700;
  color: rgb(119, 175, 24);
}

.impact-saved .result-description {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 1rem;
}

.impact-saved .result-info-container {
  display: flex;
  flex-direction: row;
}

.impact-saved .result-info-icon-container {
  display: block;
  flex: 1;
}

.impact-saved .result-info-icon {
  max-width: 150px;
  width: auto;
  height: auto;
}

.impact-saved .result-info-description {
  text-align: left;
  font-size: 1.2vh;
  line-height: normal;
  font-weight: 500;
}

.impact-info .impact-info-title-row {
  display: flex;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}

.impact-info .impact-info-row {
  display: flex;
  flex-direction: row;
}

.impact-info .impact-info-col-img {
  display: flex;
  flex: 1;
}

.impact-info .impact-info-img {
  max-height: 100px;
}

.impact-info .impact-info-col-percentage {
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: 700;
  font-size: 36px;
}

.impact-info .impact-info-col-title {
  display: flex;
  flex: 3;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
}
